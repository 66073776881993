import React, { Dispatch, useEffect, useState } from 'react';
import {
    Row,
    Col
} from 'reactstrap';
import { connect } from 'react-redux';
import { eventoSeleccionado, GenericObject } from '../../../types/types';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { parseJWt } from '../../../utilities/utilities';
import TwitterIcon from '../../../utilities/TwitterIcon';
import '../../sass/evento_seleccionado/paso_uno.scss';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '../../components/Button'
import { getDebtByEvent } from '../../../api/purchase'
import { useNavigate } from 'react-router-dom';
import { getLangValue } from '../../../api/config';
import { setCard } from '../../../reducer/events-reducer';
import TextField from '../TextField';
import { mostrarError } from '../../../reducer/config-reducer';
import UbicacionFotos from '../eventos/UbicacionFotos';


interface propiedades {
    eventoSeleccionado: eventoSeleccionado;
    idVendedor: string,
    mostrarError:Dispatch<GenericObject | null>,
    setCard: (debtIdentificator: string | null) => void;
}

type configObjectEventos = {
    eventsReducer: {
        eventoSeleccionado: eventoSeleccionado;
        idVendedor: string;
    }
}

declare global {
    interface Window { loadFacebookPixel: any; }
}

window.loadFacebookPixel = window.loadFacebookPixel || {};

const PasoUnoPago = (props: propiedades) => {
    const [horaSeleccionada, setHoraSeleccionada] = useState('')
    const navigate = useNavigate();
    const [error, setError] = useState<string>('');
    const [debtIdentificator, setDebtIdentificator] = useState<string>('');
    const { eventoSeleccionado } = props;

    useEffect(() => {
        if (horaSeleccionada && localStorage.getItem('token')) {
            if (horaSeleccionada !== parseJWt(localStorage.getItem('token')!).sessionEventId) {
                localStorage.removeItem('token')
            }
        }
    }, [horaSeleccionada])

    useEffect(() => {
        if (eventoSeleccionado.pixelFacebook1) {
            window.loadFacebookPixel(eventoSeleccionado.pixelFacebook1)
        }
        if (eventoSeleccionado.pixelFacebook2) {
            window.loadFacebookPixel(eventoSeleccionado.pixelFacebook2)
        }
    }, [eventoSeleccionado]);



    const nextStep = (token: string) => {
        localStorage.setItem('token', token);
        const hash = window.location.pathname.split("/")[2];
        navigate(`/pagos/${hash}/step/2`)
    }

    const buyTicketHandler = async () => {
        const hash = window.location.pathname.split("/")[2];
        try {
            const res = await getDebtByEvent(hash, debtIdentificator);
            if (res) {
                localStorage.removeItem('referrer')
                nextStep(res.token)
            } else {
                setError("El Código de Pago ingresado, no existe o ya fue pagado");
            }
        } catch (error: any) {
            props.mostrarError({mensajeAMostrar: error!.message});
        }
        
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        let str:string 
        var pattern =  /^[a-zA-Z0-9]*$/;
            if (pattern.test(event.target.value)) {
                str = event.target.value;
            }else{
                return;
            }
        
        setDebtIdentificator(str);
    }

    const volverAtras = () => {
        navigate(`/`)
    }

    return (
        <>
            {!eventoSeleccionado.image &&
                <Row>
                    <Col> <p className="h4 titulo_evento_en_paso text_2" dangerouslySetInnerHTML={{ __html: eventoSeleccionado.title }} /></Col>
                </Row>}
            <Row>
                <Col md={12} className={'mt-3 no-image-no-description'}>
                    <Row>
                        <Col>
                            <div className='rounded-circle abajo-icon float-end mt-2  animate__animated animate__bounce'><KeyboardArrowDownIcon /></div>
                        </Col>
                    </Row>
                    <Row className='compra-paso-uno'>
                        <Col className='text-start event-data'>
                            <div className='columna-event-data'>
                            <div className='pay-debts'>
                                {(function () {
                                    if (eventoSeleccionado.status === "0") {
                                        return (
                                            <>
                                                <Button className='back_2 p-3 ps-4 pe-4 mt-4' onClick={volverAtras}> IR AL INICIO </Button>
                                            </>
                                        );
                                    } else if (eventoSeleccionado.sessions) {
                                        return (<>
                                                    <h3 style={{"color":"#000"}}>Ingresa Código de Pago</h3>
                                                    <TextField required={true} type='text' id='codigoPago' label='Código de Pago' value={debtIdentificator ? debtIdentificator : ""} errorMsg={error} handleInputChange={handleInputChange} focusEvent={() => { setError(''); }} />
                                                    <Button disabled={debtIdentificator === null || debtIdentificator === "" || debtIdentificator.length < 6} onClick={buyTicketHandler}>PAGAR &gt;</Button>
                                        </>);
                                    }
                                })()}
                            </div>
                            </div>
                            <div className='columna-event-data'>
                                <h4 className={'mt-2 subtitles'}>Lugar</h4>
                                <UbicacionFotos
                                    lugar={eventoSeleccionado.place}
                                    address={eventoSeleccionado.address ? eventoSeleccionado.address : null}
                                    map={eventoSeleccionado.map}
                                    medialist={eventoSeleccionado.mediaList}
                                    location={eventoSeleccionado.location}
                                />
                                <Row>
                                    <Row>
                                        <Col>
                                            {eventoSeleccionado.description && <>
                                                <h4 className='mt-4 subtitles'>Info General</h4>
                                                <Row>
                                                    <Col className='ps-4 mb-4'><div dangerouslySetInnerHTML={{ __html: eventoSeleccionado.description }} /></Col>
                                                </Row>
                                            </>}
                                        </Col>
                                    </Row>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

const mapStateToProps = (state: configObjectEventos) => {
    return {
        eventoSeleccionado: state.eventsReducer.eventoSeleccionado,
        idVendedor: state.eventsReducer.idVendedor
    }
}

export default connect(
    mapStateToProps,
    {setCard, mostrarError}
)(PasoUnoPago);
