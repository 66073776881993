import { connect } from 'react-redux';
import {Row,Col} from 'reactstrap';
import '../../sass/pasos_compra.scss';
import { eventoSeleccionado } from '../../../types/types';

let pasosCompra = [
    { id: "1", title: "Comienza el Pago" },
    { id: "2", title: "Ingresa tus Datos" },
    { id: "3", title: "Realiza el Pago" }
  ];
  type propiedades = {
    pasoActualCompra:string,
    eventoSeleccionado: eventoSeleccionado;
}
export type configObjectEventos = {
    eventsReducer: propiedades
}

const pasosPago = (props:propiedades) => {
    return (
        <Row className='pasoscompra ps-0'>
            {props.eventoSeleccionado.sessions && pasosCompra.map((paso, index)=>{
                return (
                <Col key={index} xs={4} className='p-0'>
                    <div className={paso.id === props.pasoActualCompra ? 'paso paso-actual' : 'paso'}>{paso.id === props.pasoActualCompra ? paso.title : paso.id}</div>
                </Col>)
            })}
            {!props.eventoSeleccionado.sessions && props.eventoSeleccionado.publicPresentationText &&
                <Col className='text-center pt-3 proximamente'>{props.eventoSeleccionado.publicPresentationText}</Col>
            }
        </Row>
    );
};

const mapStateToProps = (state: configObjectEventos) => {
    return {
        pasosCompra: pasosCompra,
        pasoActualCompra: state.eventsReducer.pasoActualCompra,
        eventoSeleccionado: state.eventsReducer.eventoSeleccionado,
    }
}

export default connect(
    mapStateToProps,null
)(pasosPago);

