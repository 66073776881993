import { useState, useEffect, ChangeEventHandler, useRef } from 'react';
import { connect } from 'react-redux';
import { datosCompra } from '../../../types/types';
import { Row, Col, Button} from 'reactstrap';
import DetalleCompra from '../compra/DetalleCompra';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { comprarPasoTres, redSysRedirect, validateDni, validateEmail } from '../../../api/purchase';
import { useLocation, useNavigate } from 'react-router-dom';
import { irAlPasoCuatro } from '../../../reducer/events-reducer'
import { getBuyerData } from '../../../api/purchase';
import TextField from '../TextField';
import { Checkbox } from '@mui/material';
import { getLangValue, getLocalities, getProvinciasCompra } from '../../../api/config';
import { fetchTextoAyuda } from '../../../reducer/config-reducer';
import { InfoOutlined } from '@mui/icons-material';
import MuiTextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FormattedMessage } from 'react-intl';
import reCAPTCHA from '../../../utilities/reCAPTCHA';
import { CustomField, hasTypeForm, parseCustomFields, parseJsonStringToArray } from '../../../utilities/utilities';
import MercadoPagoDeviceId from '../../../utilities/MercadopagoDeviceId';
import TituloEvento from '../eventos/TituloEvento';

type configObjectDatosComprados = {
    eventsReducer: {
        detalleCompra: datosCompra;
        fetchTextoAyuda: (txt: string, tit: string) => void;
        textoAyuda: string
    };
};

type bizum = {
    dsSignatureVersion: string,
    dsMerchantParameters: string,
    dsSignature: string
};

interface ProvinciaOption {
    id: string;
    label: string;
}

const PasoDosPago = ({ detalleCompra, irAlPasoCuatro, setTimeOut, fetchTextoAyuda, textoAyuda }: { detalleCompra: datosCompra, irAlPasoCuatro: (paymentType: string) => void, setTimeOut: React.Dispatch<React.SetStateAction<boolean>>, fetchTextoAyuda: (txt: string, tit: string) => void, textoAyuda: string }) => {
    
    const [customFieldTicketPerson, setCustomFieldTicketPerson] = useState<{ [key: string]: string } | null>(null);
    const [customFieldComprador, setCustomFieldComprador] = useState<{ [key: string]: string } | null>(null);
    const [isLoading, setIsLoading] = useState(false);
        
    const [formState, setFormState] = useState({
        nombre: '',
        apellido: '',
        email: '',
        repeatEmail: '',
        provincia: '',
        telefono: '',
        municipio: '',
        terminosCondiciones: false,
        ...customFieldComprador,
        ...(detalleCompra.requestNumberIdentityField && { numberIdentity: '' }),
        ...(detalleCompra.requestBusinessField && { businessName: '' }),
        ...(detalleCompra.requestBookingCode && { bookingCode: '' }),
        ...(detalleCompra.requestSellerField && { sellerName: '' }),
        ...(detalleCompra.requestTicketPersonData && {
            ticketPersonData: Array.from({ length: detalleCompra.ticketsQuantity }, () => ({
                firstName: '',
                lastName: '',
                numberIdentity: '',
                email: '',
                ...customFieldTicketPerson,
            })),
        }),
    });

    const [formStateError, setFormStateError] = useState({
        nombre: '',
        apellido: '',
        email: '',
        repeatEmail: '',
        provincia: '',
        telefono: '',
        municipio: '',
        terminosCondiciones: null,
        ...customFieldComprador,
        ...(detalleCompra.requestNumberIdentityField && { numberIdentity: '' }),
        ...(detalleCompra.requestBusinessField && { businessName: '' }),
        ...(detalleCompra.requestBookingCode && { bookingCode: '' }),
        ...(detalleCompra.requestSellerField && { sellerName: '' }),
        ...(detalleCompra.requestTicketPersonData && {
            ticketPersonData: Array.from({ length: detalleCompra.ticketsQuantity }, () => ({
                firstName: '',
                lastName: '',
                numberIdentity: '',
                email: '',
                ...customFieldTicketPerson,
            })),
        }),
    });
    const [datosDelUser, setDatosDelUser] = useState(false);

    const [checked, setChecked] = useState(false);
    const navigate = useNavigate();

    const [provinciasOpciones, setProvinciasOpciones] = useState([]);
    const [municipiosOpciones, setMunicipiosOpciones] = useState<any>([]);
    const [langValue] = useState<string>(getLangValue("lang"));
    const [recaptchaPrivateKey] = useState<string>(getLangValue("recaptcha_private_key"));
    const [terminosCondicionesSeleccionado, setTerminosCondicionesSeleccionado] = useState<boolean>(false);
    const [provinciaSeleccionada, setprovinciaSeleccionada] = useState<any>(null);
    const [municipioSeleccionado, setmunicipioSeleccionado] = useState<any>(null);

    const [formData, setFormData] = useState({
        Ds_SignatureVersion: '',
        Ds_MerchantParameters: '',
        Ds_Signature: ''
    });

    const formRef = useRef<HTMLFormElement | null>(null);
    const [recaptcha] = useState<reCAPTCHA>(new reCAPTCHA(recaptchaPrivateKey, "step3"));

    const checkLoading = (value: boolean) => {
        setIsLoading(value);
    }

    const submitPaymentRedSys = async (redsysType: string) => {
        redSysRedirect(redsysType).then((response: bizum) => {
            if (response.dsMerchantParameters) {
                
                setFormData({
                    Ds_SignatureVersion: response.dsSignatureVersion,
                    Ds_MerchantParameters: response.dsMerchantParameters,
                    Ds_Signature: response.dsSignature
                });
                if (formRef.current) {
                    const formDataEntries = Object.entries(formData);
                    formDataEntries.forEach(([fieldName, fieldValue]) => {
                        const field = formRef.current?.elements.namedItem(fieldName) as HTMLInputElement | undefined;
                        if (field) {
                            field.value = fieldValue;
                        }
                    });

                    setTimeout(() => {
                        formRef.current!.submit();
                    }, 500)

                }
            }
        });
    }
    const handleAyuda = (txt: string, title: string) => {
        fetchTextoAyuda(txt, title)
    }

    useEffect(() => {
        (async () => {
            try {
                const response = await getProvinciasCompra();
                const transformedResponse = response.map(({ id, name }: { id: string; name: string }) => ({ id, label: name }));
                setProvinciasOpciones(transformedResponse);
                const losdatosdeluser = await getBuyerData();
                if (losdatosdeluser) {
                    if (losdatosdeluser.locality && losdatosdeluser.locality) {
                        await populateMunicipios(losdatosdeluser.province);
                        municipiosOpciones.forEach((municipio: { id: string, label: string }) => {
                            if (municipio.id === losdatosdeluser.locality) {
                                setmunicipioSeleccionado({id: municipio.id, label:municipio.label});
                            }
                        })
                    }
                    if(losdatosdeluser.deviceId){
                        let deviceId = document.querySelector("#deviceId") as HTMLInputElement;
                        deviceId.value = losdatosdeluser.deviceId;
                    }
                    setFormState(prevState => ({
                        ...prevState,
                        deviceId: losdatosdeluser.deviceId,
                        nombre: losdatosdeluser.firstName || prevState.nombre,
                        apellido: losdatosdeluser.lastName || prevState.apellido,
                        email: losdatosdeluser.email || prevState.email,
                        numberIdentity: losdatosdeluser.numberIdentity || prevState.numberIdentity,
                        provincia: losdatosdeluser.province || prevState.provincia,
                        repeatEmail: losdatosdeluser.emailVerification || prevState.repeatEmail,
                        telefono: losdatosdeluser.phone || prevState.telefono,
                        municipio: losdatosdeluser.locality || prevState.municipio,
                        businessName: losdatosdeluser.businessName || prevState.businessName,
                        terminosCondiciones: losdatosdeluser.termsConditions || prevState.terminosCondiciones,
                        ...(losdatosdeluser.additionalFields)
                    }));

                    if (losdatosdeluser.termsConditions) {
                        setTerminosCondicionesSeleccionado(losdatosdeluser.termsConditions);
                    }

                    if (losdatosdeluser.province) {
                        provinciasOpciones.forEach((provincia: { id: string, label: string }) => {
                            if (provincia.id === losdatosdeluser.province) {
                                setprovinciaSeleccionada({id: provincia.id, label:provincia.label});
                            }
                        })
                    }

                    if (losdatosdeluser.buyersTicket.length > 0) {
                        setFormState(prevState => ({
                            ...prevState,
                            ticketPersonData: prevState.ticketPersonData?.map((person, idx) => {
                                if (idx === 0
                                    && losdatosdeluser.buyersTicket[idx].email === losdatosdeluser.email
                                    && losdatosdeluser.buyersTicket[idx].firstName === losdatosdeluser.firstName
                                    && losdatosdeluser.buyersTicket[idx].lastName === losdatosdeluser.lastName) {
                                    setChecked(true);
                                }
                                if (losdatosdeluser.buyersTicket[idx]) {
                                    person.email = losdatosdeluser.buyersTicket[idx].email;
                                    person.firstName = losdatosdeluser.buyersTicket[idx].firstName;
                                    person.lastName = losdatosdeluser.buyersTicket[idx].lastName;
                                    person.numberIdentity = losdatosdeluser.buyersTicket[idx].numberIdentity;
                                }
                                return {...person, ...(losdatosdeluser.buyersTicket[idx].additionalFields)};
                            })
                        }));
                    }

                    setTimeout(function () { setDatosDelUser(true);checkLoading(false); }, 500)

                    if (losdatosdeluser.firstName && losdatosdeluser.lastName) {
                        const fieldsets = document.querySelectorAll('.fieldset');
                        fieldsets.forEach(fieldset => {
                            fieldset.classList.add('focus');
                        });
                    }

                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        })();
    }, [datosDelUser]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormState(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleCheckboxTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTerminosCondicionesSeleccionado(event.target.checked);
        setFormState(prevState => ({
            ...prevState,
            ['terminosCondiciones']: event.target.checked,
        }));
    }

    const handleTicketPersonInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        const { name, value } = event.target;

        const matches = name.match(/ticketPersonData\[(\d+)\]\.(.+)/);
        if (!matches) return;

        const index = parseInt(matches[1]);
        const fieldName = matches[2];

        setFormState((prevState) => ({
            ...prevState,
            ticketPersonData: prevState.ticketPersonData!.map((person, idx) => {
                if (idx === index) {
                    return {
                        ...person,
                        [fieldName]: value,
                    };
                }
                return person;
            }),
        }));
    };

    useEffect(() => {
        window.onpageshow = (event) => {
            if (event.persisted) {
                window.location.reload();
            }
          };
      }, [datosDelUser]);

    const validateInput = async (name: string, value: string, event: React.ChangeEvent<HTMLInputElement>) => {
        let error = '';

        if (event.target.required && (value === undefined || value.trim() === '')) {
            error = 'Complete el campo';
        } else if (value.length > parseInt(event.target.getAttribute('max') || '')) {
            error = `El campo debe tener como máximo ${event.target.getAttribute('max')} caracteres`;
        }

        if (name === 'email' || name === 'repeatEmail') {
            setFormState(prevState => ({
                ...prevState,
                [name]: value.trim(),
            }));

            if (value.trim() === '') {
                error = 'Complete el campo';
            } else if (!/\S+@\S+\.\S+/.test(value)) {
                error = 'Ingrese un correo electrónico válido';
            } else if (name === 'repeatEmail' && value.trim() !== formState.email.trim()) {
                error = 'Los correos electrónicos no coinciden';
            } else if (name === 'email' && formState.repeatEmail.trim() !== '' && value.trim() !== formState.repeatEmail.trim()) {
                error = 'Los correos electrónicos no coinciden';
            } else if (name === 'repeatEmail' && value.trim() === formState.email.trim()) {
                document.querySelector("#email")!.classList.remove('has-error');
                setFormStateError(prevState => ({
                    ...prevState,
                    email: '',
                }));
            }

            if (error === '' && (detalleCompra.emailDoubleSellValidate || detalleCompra.emailValidation)) {
                const rest = await validateEmail(value);
                if (!rest.result) {
                    error = rest.payload;//'Este EMAIL ya Realizó una Operación en este Evento'
                }
            }
        }

        if (name === 'numberIdentity' && error === '') {
            if (detalleCompra.numberIdentityDoubleSellValidate) {
                const rest = await validateDni(value);
                if (!rest || !rest.result) {
                    if (!rest) {
                        error = 'Este DNI ya Realizó una Operación en Este Evento'
                    } else if(rest.payload) {
                        error = rest.payload;//error = 'Este DNI ya Realizó una Operación en Este Evento'
                    }
                }
            }
        }

        if (name === 'terminosCondiciones' && langValue === "es_ES" && !formState.terminosCondiciones) {
            error = "Terminos y Condiciones";
        }

        setFormStateError(prevState => ({
            ...prevState,
            [name]: error,
        }));

        if (error !== '') {
            event.target.classList.add('has-error');
        } else {
            event.target.classList.remove('has-error');
        }
    };
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (event.target.checked && (formState.email === "" || formState.apellido === "" || formState.nombre === "")) {
            return false;
        }

        setChecked(event.target.checked);
        let email = formState.email;
        let nombre = formState.nombre;
        let apellido = formState.apellido;

        if (!event.target.checked) {
            email = "";
            nombre = "";
            apellido = "";
        }

        setFormState(prevState => ({
            ...prevState,
            ticketPersonData: prevState.ticketPersonData?.map((person, idx) => {
                if (idx === 0) {
                    person.email = email;
                    person.firstName = nombre;
                    person.lastName = apellido;
                    person.numberIdentity = '';
                }
                return person;
            })
        }));
        const fieldsets = document.querySelectorAll('.fieldset');
        fieldsets.forEach(fieldset => {
            fieldset.classList.add('focus');
        });
    }
    const validateTicketPersonInput = (
        name: string,
        value: string,
        index: number,
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
        let error = '';
        if(value === undefined){
            value = event.target.value;
        }
        if (event.target.required && value.trim() === '') {
            error = 'Complete el campo';
        } else if (value.length > parseInt(event.target.getAttribute('max') || '')) {
            error = `El campo debe tener como máximo ${event.target.getAttribute('max')} caracteres`;
        }

        if (name === 'email') {
            if (value.trim() === '') {
                error = 'Complete el campo';
            } else if (!/\S+@\S+\.\S+/.test(value)) {
                error = 'Ingrese un correo electrónico válido';
            }
        }

        setFormStateError((prevState) => ({
            ...prevState,
            ticketPersonData: prevState.ticketPersonData!.map((person, idx) => {
                if (idx === index) {
                    return {
                        ...person,
                        [name]: error,
                    };
                }
                return person;
            }),
        }));
    };


    const populateMunicipios = async (provinceId: string) => {
        const resp: [] = await getLocalities(provinceId);
        const transformedResponse = resp.map(({ id, name }: { id: string; name: string }) => ({ id, label: name }));
        setMunicipiosOpciones(transformedResponse);
    };

    const handleCustomAutoComplete = (newValue: string | null, attributeName: string) => {
        setTimeout(()=>{
            if(newValue){
                setFormStateError(prevState => ({
                    ...prevState,
                    [attributeName]: '',
                }));
                setFormState(prevState => ({
                    ...prevState,
                    [attributeName]: newValue,
                }));
            }
            else{
                setFormStateError(prevState => ({
                    ...prevState,
                    [attributeName]: 'Complete el campo',
                }));
                setFormState(prevState => ({
                    ...prevState,
                    [attributeName]: "",
                }));
            }
        },500)
        
    }

    const isFormValid = () => {

        if(isLoading) {
            return true;
        }

        const requiredFields = document.querySelectorAll('input[required]') as NodeListOf<HTMLInputElement>;
        
        for (let i = 0; i < requiredFields.length; i++) {
            if (requiredFields[i].value.trim() === '') {
                return true;
            }
        }
        
        const formErrorValues = Object.values(formStateError);
        
        if (formErrorValues.some((value) => value !== '' && typeof (value) !== 'object')) {
            return true;
        }
        
        const requiredSelectFields = document.querySelectorAll('select[required]') as NodeListOf<HTMLSelectElement>;
        for (let i = 0; i < requiredSelectFields.length; i++) {
            if (requiredSelectFields[i].value.trim() === '') {
                return true;
            }
        }

        if (provinciaSeleccionada === null) {
            return true;
        }

        if (municipioSeleccionado === null) {
            return true;
        }

        if (langValue === 'es_ES' && !terminosCondicionesSeleccionado) {
            return true;
        }

        return false;
    };

    const handleInputProvinciaChange = (name: string, value: string, provincia: string) => {
        setFormState(prevState => ({
            ...prevState,
            [name]: value,
        }));
        setprovinciaSeleccionada({ id: value, label: provincia })
    }

    const handleInputMunicipioChange = (name: string, value: string, municipio: string) => {
        setFormState(prevState => ({
            ...prevState,
            [name]: value,
        }));
        setmunicipioSeleccionado({id:value, label:municipio})
    }

    useEffect(() => {
        if(langValue === 'es_AR') {
            const mercadoPagoDeviceId = new MercadoPagoDeviceId();
        }
        
        if (detalleCompra.customFields !== null){
            setCustomFieldComprador(parseCustomFields(detalleCompra.customFields, 'c'));
            const customFieldsDetail = parseCustomFields(detalleCompra.customFields, 'pe');
            setCustomFieldTicketPerson(customFieldsDetail);
            if(customFieldsDetail !== null){
                setFormState((prevState) => ({
                    ...prevState,
                    ticketPersonData: Array.from({ length: detalleCompra.ticketsQuantity }, () => ({
                        firstName: '',
                        lastName: '',
                        numberIdentity: '',
                        email: '',
                        ...customFieldTicketPerson,
                    })),
                }));
                setFormStateError((prevState) => ({
                    ...prevState,
                    ticketPersonData: Array.from({ length: detalleCompra.ticketsQuantity }, () => ({
                        firstName: '',
                        lastName: '',
                        numberIdentity: '',
                        email: '',
                        ...customFieldTicketPerson,
                    })),
                }));
            }
            
        }
    }, [])

    const submitFormHandler = async (paymentType: string) => {
        checkLoading(true);
        let token: string = await recaptcha.getToken();
        let objCustomField:null | { [key: string]: string; } = {};
        if(detalleCompra.customFields){
            objCustomField = parseCustomFields(detalleCompra.customFields, 'c');
            if(objCustomField !== null){
                for (const key in formState) {
                    if (formState.hasOwnProperty(key)) {
                        // Asignamos el valor del objeto destino al objeto origen si la clave coincide
                        if (objCustomField.hasOwnProperty(key)) {
                            objCustomField[key] = (formState as Record<string, any>)[key];
                        }
                    }
                }
            }
        }

        const deviceId = document.querySelector("#deviceId") as HTMLInputElement;

        const request = {
            deviceId: deviceId.value,
            additionalFields: objCustomField,
            firstName: formState.nombre,
            recaptchaToken: token,
            lastName: formState.apellido,
            email: formState.email,
            emailVerification: formState.repeatEmail,
            phone: Number(formState.telefono),
            province: formState.provincia,
            locality: formState.municipio,
            numberIdentity: formState.numberIdentity ? Number(formState.numberIdentity) : null,
            businessName: formState.businessName ? formState.businessName : null,
            bookingCode: formState.bookingCode ? formState.bookingCode : null,
            sellerName: formState.sellerName ? formState.sellerName : null,
            termsConditions: formState.terminosCondiciones ? formState.terminosCondiciones : false,
            buyersTicket: formState.ticketPersonData
                ? formState.ticketPersonData.map((person) => ({
                    ...person,
                    numberIdentity: person.numberIdentity ? Number(person.numberIdentity) : null,
                })) as [{ firstName: string; lastName: string; email: string; numberIdentity: number | null; }] | null
                : null,
        };

        try {
            
            const res = await comprarPasoTres(request, paymentType);
            if (res) {
                
                if (paymentType === 'bizum' || paymentType === 'redsys') {
                    submitPaymentRedSys(paymentType);
                } else {
                    if (res.checkoutType === 'freePaid') {
                        navigate(`/pago-finalizado/freePaid/${res.checkoutData.code}`)
                    } else {
                        const hash = window.location.pathname.split("/")[2];
                        navigate(`/pagos/${hash}/step/3?type=${paymentType}`)
                    }
                }
            }    
        } catch (error) {
            console.log(error);
            checkLoading(false);   
        } 
    };


    const inputProps = {
        className: 'p-2 mb-2',
    };

    return (
        <div className='compra-paso-tres'>
            <div><p className="h4 titulo_evento_en_paso text_2" dangerouslySetInnerHTML={{ __html: detalleCompra.eventName }} /></div>
            <Row>
                <Col>
                    <h4 className='mb-2 subtitle'>Completa tus Datos</h4>
                </Col>
            </Row>
            <div className='mb-4 form-datos'>
            <input type="hidden" id="deviceId" name='deviceId' onChange={handleInputChange}/>
                {(detalleCompra.requestNumberIdentityField ||  detalleCompra.numberIdentityDoubleSellValidate) &&
                    <TextField required={true} type={'number'} value={formState.numberIdentity!} id={'numberIdentity'} handleInputChange={handleInputChange} validate={validateInput} label='DNI (sólo números)' errorMsg={formStateError.numberIdentity!} max={9} />
                }
                {detalleCompra.requestBookingCode &&
                    <TextField required={true} type='text' value={formState.bookingCode!} id={'bookingCode'} handleInputChange={handleInputChange} validate={validateInput} label='Código de Reserva o Verif' errorMsg={formStateError.bookingCode!} max={20} />
                }
                <TextField required={true} type={'text'} value={formState.nombre} id={'nombre'} handleInputChange={handleInputChange} validate={validateInput} label='Nombre' errorMsg={formStateError.nombre} max={35} />
                <TextField required={true} type={'text'} value={formState.apellido} id={'apellido'} handleInputChange={handleInputChange} validate={validateInput} label='Apellido' errorMsg={formStateError.apellido} max={35} />
                {detalleCompra.requestBusinessField &&
                    <TextField required={true} type={'text'} value={formState.businessName!} id={'businessName'} handleInputChange={handleInputChange} validate={validateInput} label='Empresa / Medio / Institución y N° CUIT' errorMsg={formStateError.businessName!} max={50} />
                }
                <TextField required={true} type={'text'} value={formState.email} id={'email'} handleInputChange={handleInputChange} validate={validateInput} label='Correo Electrónico' errorMsg={formStateError.email} max={50} preventPasteEvent={true} spellcheck={false} />
                <TextField required={true} type={'text'} value={formState.repeatEmail} id={'repeatEmail'} handleInputChange={handleInputChange} validate={validateInput} label='Repite tu Correo Electrónico' errorMsg={formStateError.repeatEmail} max={50} preventPasteEvent={true} spellcheck={false} />
                <TextField required={true} type={'number'} value={formState.telefono} id={'telefono'} handleInputChange={handleInputChange} validate={validateInput} label='Teléfono (sólo números)' errorMsg={formStateError.telefono} max={50} />
                <div className='pt-2'>
                    <Autocomplete
                        disablePortal
                        options={provinciasOpciones as ProvinciaOption[]} // Asegúrate de que provinciasOpciones sea un array de ProvinciaOption
                        getOptionLabel={(option) => option.label}
                        value={provinciaSeleccionada}
                        renderInput={(params) => <MuiTextField {...params} label="Provincia" />}
                        onChange={(event, provincia) => {
                            if (provincia) {
                                handleInputProvinciaChange('provincia', provincia.id, provincia.label);
                                populateMunicipios(provincia.id);
                                setmunicipioSeleccionado(null)
                                setFormStateError(prevState => ({
                                    ...prevState,
                                    provincia: '',
                                }));
                            } else {
                                setFormStateError(prevState => ({
                                    ...prevState,
                                    provincia: 'Complete el campo',
                                }));
                            }
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                    {formStateError.provincia && <span className="error">{formStateError.provincia}</span>}
                </div>
                <div className='pt-3'>
                    <Autocomplete
                        disablePortal
                        options={municipiosOpciones as ProvinciaOption[]} // Asegúrate de que provinciasOpciones sea un array de ProvinciaOption
                        getOptionLabel={(option) => option.label}
                        value={municipioSeleccionado}
                        renderInput={(params) => <MuiTextField {...params} label={<FormattedMessage id="label_municipio" defaultMessage="Municipio"/>} />}
                        onChange={(event, municipio) => {
                            if (municipio) {
                                handleInputMunicipioChange('municipio', municipio.id, municipio.label);
                                setFormStateError(prevState => ({
                                    ...prevState,
                                    municipio: '',  
                                }));
                            } else {
                                setFormStateError(prevState => ({
                                    ...prevState,
                                    municipio: 'Complete el campo',
                                }));
                            }
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                    {formStateError.municipio && <span className="error">{formStateError.municipio}</span>}
                </div>
                {detalleCompra.requestSellerField &&
                    <TextField required={true} type={'text'} value={formState.sellerName!} id={'sellerName'} handleInputChange={handleInputChange} validate={validateInput} label='Nombre Vendedor' errorMsg={formStateError.sellerName!} max={25} />
                }
                {detalleCompra.customFields && parseJsonStringToArray(detalleCompra.customFields).map((customField: CustomField) => {
                    if (customField.tipo_formulario === 'c') {
                        let fieldValue = '';
                        let fieldError = '';
                        const attributeName = customField.attribute;
                        if(attributeName in formState){
                            fieldValue = (formState as Record<string, any>)[attributeName];
                            fieldError = (formStateError as Record<string, any>)[attributeName];
                        }
                        if (customField.tipo_campo === 'alf' || customField.tipo_campo === 'nsd' || customField.tipo_campo === 'ncd') {
                            const isNumeric = (customField.tipo_campo === 'nsd' || customField.tipo_campo === 'ncd');
                            return (<TextField required={customField.mandatorio === '1'} type={isNumeric ? 'number' : 'text'} value={fieldValue} id={attributeName} handleInputChange={handleInputChange} validate={validateInput}  label={customField.nombre} errorMsg={fieldError!} max={100} otherProps={{"data-is_numeric": isNumeric}} />)
                        }
                        if(customField.tipo_campo === 'ld'){

                            const options: string[] = customField.elemento_lista_desplegable ? customField.elemento_lista_desplegable.split('\r\n') : [];

                            return (<div className='mt-2'><Autocomplete
                                disablePortal
                                id={attributeName}
                                options={options}
                                getOptionLabel={(option) => option}
                                value={fieldValue}
                                renderInput={(params) => <MuiTextField {...params} label={customField.nombre} required={customField.mandatorio === '1'} onChange={handleInputChange}/>}
                                onChange={(e, newValue) => {
                                    handleCustomAutoComplete(newValue, attributeName);
                                }}
                            /></div>)
                        }
                    }
                })}
                {(detalleCompra.requestTicketPersonData  || (customFieldTicketPerson !== null && hasTypeForm(detalleCompra.customFields, 'pe'))) && (
                    <div>
                        <h2 className='mt-4 text-start subtitle'>Completa los Datos de los Asistentes</h2>
                        
                        {detalleCompra.requestTicketPersonData && <>
                            <Row>
                                <Col xs={9}>
                                    <p className='tomar-datos-asistente'>Tomar datos del Comprador para Datos Asistente 1</p>
                                </Col>
                                <Col xs={1}><Checkbox className="check-asistentes" checked={checked} onChange={handleCheckboxChange} inputProps={{ 'aria-label': 'controlled' }} /></Col>
                            </Row>
                            <Row>
                                <Col><span className='aclaracion'>(no olvides completar igualmente el campo DNI)</span></Col>
                            </Row>
                            <div className='d-flex justify-content-center time-date-wrapper'>
                                <div className='d-flex text-center'>


                                </div>
                            </div>
                        </>}

                        {formState.ticketPersonData && formState.ticketPersonData!.map((person, index) => (
                            <div key={index}>
                                <h5 className='mt-3 text-start datos-asistente'>Datos Asistente {index + 1}</h5>
                                
                                {detalleCompra.requestTicketPersonData && <>
                                    <TextField required={true} type={'text'} index={index} value={person.firstName} id={`ticketPersonData[${index}].firstName`} handlePersonInputChange={handleTicketPersonInputChange} validatePerson={validateTicketPersonInput} label='Nombre' errorMsg={formStateError.ticketPersonData![index].firstName} max={35} />
                                    <TextField required={true} type={'text'} index={index} value={person.lastName} id={`ticketPersonData[${index}].lastName`} handlePersonInputChange={handleTicketPersonInputChange} validatePerson={validateTicketPersonInput} label='Apellido' errorMsg={formStateError.ticketPersonData![index].lastName} max={35} />
                                    <TextField required={true} type={'number'} index={index} value={person.numberIdentity} id={`ticketPersonData[${index}].numberIdentity`} handlePersonInputChange={handleTicketPersonInputChange} validatePerson={validateTicketPersonInput} label='Número de Identificación (DNI, etc.)' errorMsg={formStateError.ticketPersonData![index].numberIdentity} max={8} />
                                    <TextField required={true} type={'text'} index={index} value={person.email} id={`ticketPersonData[${index}].email`} handlePersonInputChange={handleTicketPersonInputChange} validatePerson={validateTicketPersonInput} label='Correo Electrónico' errorMsg={formStateError.ticketPersonData![index].email} max={50} />
                                </>}
                                
                                {customFieldTicketPerson && parseJsonStringToArray(detalleCompra.customFields).map((customField: CustomField) => {
                                    if (customField.tipo_formulario === 'pe') {
                                        let fieldValue = '';
                                        let fieldError = '';
                                        const attributeName = customField.attribute;
                                        if(attributeName in formState.ticketPersonData![index]){
                                            fieldValue = (formState.ticketPersonData![index] as Record<string, any>)[attributeName];
                                            fieldError = (formStateError.ticketPersonData![index] as Record<string, any>)[attributeName];
                                        }

                                        if (customField.tipo_campo === 'alf' || customField.tipo_campo === 'nsd' || customField.tipo_campo === 'ncd') {
                                            const isNumeric = (customField.tipo_campo === 'nsd' || customField.tipo_campo === 'ncd');
                                            return (<TextField required={customField.mandatorio === '1'} type={isNumeric ? 'number' : 'text'} index={index} value={fieldValue} id={`ticketPersonData[${index}].${attributeName}`} handlePersonInputChange={handleTicketPersonInputChange} validatePerson={validateTicketPersonInput}  label={customField.nombre} errorMsg={fieldError!} max={100} otherProps={{"data-is_numeric": isNumeric}} />)
                                        }
                                        if(customField.tipo_campo === 'ld'){

                                            const options: string[] = customField.elemento_lista_desplegable ? customField.elemento_lista_desplegable.split('\r\n') : [];

                                            return (<div className='mt-2'><Autocomplete
                                                disablePortal
                                                id={`ticketPersonData[${index}].${attributeName}`}
                                                options={options} // Asegúrate de que provinciasOpciones sea un array de ProvinciaOption
                                                getOptionLabel={(option) => option}
                                                value={fieldValue}
                                                onChange={(e, newValue) => {
                                                    if (newValue) {
                                                        setFormState((prevState) => ({
                                                            ...prevState,
                                                            ticketPersonData: prevState.ticketPersonData!.map((person, idx) => {
                                                                if (idx === index) {
                                                                    return {
                                                                        ...person,
                                                                        [attributeName]: newValue,
                                                                    };
                                                                }
                                                                return person;
                                                            }),
                                                        }));
                                                        setFormStateError((prevState) => ({
                                                            ...prevState,
                                                            ticketPersonData: prevState.ticketPersonData!.map((person, idx) => {
                                                                if (idx === index) {
                                                                    return {
                                                                        ...person,
                                                                        [attributeName]: "",
                                                                    };
                                                                }
                                                                return person;
                                                            }),
                                                        }));
                                                    } else {
                                                        setFormStateError((prevState) => ({
                                                            ...prevState,
                                                            ticketPersonData: prevState.ticketPersonData!.map((person, idx) => {
                                                                if (idx === index) {
                                                                    return {
                                                                        ...person,
                                                                        [attributeName]: "Complete el campo",
                                                                    };
                                                                }
                                                                return person;
                                                            }),
                                                        }));
                                                    } 
                                                }}
                                                renderInput={(params) => <MuiTextField {...params} label={customField.nombre} required={customField.mandatorio === '1'}/>}
                                            /></div>)
                                        }
                                    }
                                })}
                            </div>
                        ))}
                    </div>
                )}

            </div>
            <Row className="mt-4 mb-4">
                <Col>
                    {detalleCompra && <DetalleCompra pasoTres={true} />}
                </Col>
            </Row>
            {detalleCompra.freeEvent &&
                <Row className='d-flex mt-4 mb-3'>
                    <Col className='d-flex justify-content-between steps-buttons flex-column'>
                        {langValue === 'es_ES' &&
                            <>
                                <p><Checkbox id='terminosCondiciones' name='terminosCondiciones' checked={formState.terminosCondiciones} value={formState.terminosCondiciones} onChange={handleCheckboxTermsChange} inputProps={{ 'aria-label': 'controlled' }} /><span className='aclaracion'>Acepto Terminos y Condiciones</span> <span><a href='#' onClick={(e) => { e.preventDefault(); handleAyuda('defensa-del-consumidor', 'consumer_defense_modal_title') }}><InfoOutlined color='secondary' className='help-icon' style={{ "fontSize": "1.5rem" }} /></a></span></p>
                            </>
                        }
                        <Button className='mb-2 btn-bold' disabled={isFormValid()} onClick={() => { submitFormHandler('freePaid') }}> Confirmar Entrega </Button>
                        <Button className='back_2' onClick={() => {
                            window.history.go(-1);
                        }}><ArrowBackIosIcon /> Paso Anterior</Button>
                    </Col>
                </Row>}
            {!detalleCompra.freeEvent && langValue === 'es_AR' && <Row className='d-flex mt-4 mb-3'>
                <Col className='d-flex justify-content-between steps-buttons flex-column'>
                    <Button className='mb-2 btn-bold' disabled={isFormValid()} onClick={() => { submitFormHandler('debitCreditCard') }}>
                        {!detalleCompra.enableCash && !detalleCompra.enableDirectPaid ? 'Ir al Pago' : 'Pagar con T. Débito o Crédito'}
                    </Button>
                    {detalleCompra.enableCash && <Button className='mb-2 btn-bold' disabled={isFormValid()} onClick={() => { submitFormHandler('cash') }}> Pagar en RapiPago o PagoFácil</Button>}
                    {detalleCompra.enableDirectPaid && <Button className='mb-2 btn-bold' disabled={isFormValid()} onClick={() => { submitFormHandler('directPaid') }}> Dinero en Cta. MercadoPago </Button>}
                    <Button className='back_2' onClick={() => {
                        window.history.go(-1)
                    }}><ArrowBackIosIcon /> Paso Anterior</Button>
                </Col>
            </Row>}
            {!detalleCompra.freeEvent && langValue === 'es_ES' && <Row className='d-flex mt-4 mb-3'>
                <Col className='d-flex justify-content-between steps-buttons flex-column'>
                    <p><Checkbox id='terminosCondiciones' name='terminosCondiciones' checked={formState.terminosCondiciones} value={formState.terminosCondiciones} onChange={handleCheckboxTermsChange} inputProps={{ 'aria-label': 'controlled' }} /><span className='aclaracion'>Acepto Terminos y Condiciones</span> <span><a href='#' onClick={(e) => { e.preventDefault(); handleAyuda('defensa-del-consumidor', 'consumer_defense_modal_title') }}><InfoOutlined color='secondary' className='help-icon' style={{ "fontSize": "1.5rem" }} /></a></span></p>
                    <Button className='mb-2 btn-bold' disabled={isFormValid()} onClick={() => { submitFormHandler('redsys') }}>
                        PAGAR CON TARJETA
                    </Button>
                    <form action='https://sis.redsys.es/sis/realizarPago' ref={formRef} method="POST">
                        <input type="hidden" name="Ds_SignatureVersion" value={formData.Ds_SignatureVersion} />
                        <input type="hidden" name="Ds_MerchantParameters" value={formData.Ds_MerchantParameters} />
                        <input type="hidden" name="Ds_Signature" value={formData.Ds_Signature} />
                    </form>
                    <Button className='mb-2 btn-bold' disabled={isFormValid()} onClick={() => { submitFormHandler('bizum'); }}>
                        PAGAR CON BIZUM
                    </Button>
                    <Button className='back_2' onClick={() => {
                        window.history.go(-1)
                    }}><ArrowBackIosIcon /> Paso Anterior</Button>
                </Col>
            </Row>}
        </div>
    );
};

const mapStateToProps = (state: configObjectDatosComprados) => {
    return {
        detalleCompra: state.eventsReducer.detalleCompra,
        textoAyuda: state.eventsReducer.textoAyuda
    }
};

export default connect(
    mapStateToProps,
    { irAlPasoCuatro, fetchTextoAyuda }
)(PasoDosPago);
