import React, { useEffect, useState } from 'react';
import { paymentSuccess, sendComment } from '../../../api/purchase';
import { Alert, Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import TextArea from 'antd/es/input/TextArea';
import { useNavigate } from 'react-router-dom';
import { getLangValue } from '../../../api/config';

type Propiedades = {
    additionalCode?: string;
};

type ResPaymentSuccess = {
    paragraph1: string | null;
    paragraph2: string | null;
    paragraph3: string | null;
    paragraph4: string | null;
    feedbackParagraph: string | null;
    ticketsUrl: string;
    sendCommentsButton: boolean;
    pixelFacebook1: string | null;
    pixelFacebook2: string | null;
    paymentSuccessTitle: string | null;
};

declare global {
    interface Window { loadFacebookPixel: any; }
}

window.loadFacebookPixel = window.loadFacebookPixel || {};

const DebitCreditCard = (props: Propiedades) => {
    const { additionalCode } = props;
    const [modal, setModal] = useState<boolean>(false);
    const [isSuccess, setSuccess] = useState<boolean>(false);
    const [isError, setError] = useState<boolean>(false);
    const [message, setMessage] = useState('');
    const [paymentSuccessText, setPaymentSuccessText] = useState<ResPaymentSuccess | null>(null);
    const [lang] = useState<string>(`${getLangValue("lang")}`);
    const navigate = useNavigate();

    const goBackHandler = () => {
        navigate(`/`)
    }
    useEffect(() => {
        (async () => {
            const res: ResPaymentSuccess = await paymentSuccess(additionalCode ? additionalCode : "");
            
            setPaymentSuccessText(res);
            
            if (res.pixelFacebook1) {
             window.loadFacebookPixel(res.pixelFacebook1)
            }
    
            if (res.pixelFacebook2) {
                window.loadFacebookPixel(res.pixelFacebook2)
            }
        })();
    }, [additionalCode]);

    const downloadTicketsHandler = () => {
        window.location.href = `${paymentSuccessText?.ticketsUrl}`;
    }

    const toggle = () => {
        setModal(!modal);
    };

    const sendCommentMessage = async () => {
        if(additionalCode) {
            await sendComment(additionalCode, message)
            .then(() => {setSuccess(true)})
            .catch(() => {
                setError(true)
              });
            
        }
    }

    return (
        <div className='text-start'>
            {paymentSuccessText && (
                <>
                    {paymentSuccessText.paymentSuccessTitle && <div className='title text_3 mt-4' dangerouslySetInnerHTML={{ __html: paymentSuccessText.paymentSuccessTitle }} ></div>}
                    {paymentSuccessText.paragraph3 && lang === 'es_ES' &&
                        <div className='button-container mb-3 text-center'>
                            <div dangerouslySetInnerHTML={{ __html: paymentSuccessText.paragraph3 }} />
                            <div className='d-flex justify-content-center pt-2 pb-2'><Button onClick={downloadTicketsHandler}> DESCARGAR PDF</Button></div>
                        </div>
                    }
                    {paymentSuccessText.paragraph1 && <div dangerouslySetInnerHTML={{ __html: paymentSuccessText.paragraph1 }} />}
                    {paymentSuccessText.paragraph2 && <div dangerouslySetInnerHTML={{ __html: paymentSuccessText.paragraph2 }} />}
                    {paymentSuccessText.paragraph3 && lang === 'es_AR' &&
                        <div className='button-container mb-3 text-center'>
                            <div dangerouslySetInnerHTML={{ __html: paymentSuccessText.paragraph3 }} />
                            <div className='d-flex justify-content-center pt-2 pb-2'><Button onClick={downloadTicketsHandler}> DESCARGAR PDF</Button></div>
                        </div>
                    }
                    {paymentSuccessText.paragraph4 && <div dangerouslySetInnerHTML={{ __html: paymentSuccessText.paragraph4 }} />}
                    {paymentSuccessText.sendCommentsButton && paymentSuccessText.feedbackParagraph &&
                        <div className='button-container mb-3'>
                            <div className='text-center' dangerouslySetInnerHTML={{ __html: paymentSuccessText.feedbackParagraph }} />
                            <div className='d-flex justify-content-center mb-4 mt-2'><Button onClick={toggle}>ENVIAR OPINION</Button></div>    
                            <Modal isOpen={modal} toggle={toggle} centered={true} className=''>
                                <ModalHeader toggle={toggle}>ENVIA TUS COMENTARIOS</ModalHeader>
                                <>
                                    <ModalBody className='text-center modal-comment-body'>
                                        {!isSuccess && !isError &&
                                        <>
                                            <Row className='form-data'>
                                                <Col className=''>
                                                    <TextArea className='form-control' rows={5} onChange={(e)=>{setMessage(e.target.value)}} value={message} required placeholder='Escribe aquí tu Sugerencia o Comentario ...'></TextArea>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className='d-flex justify-content-center mb-4 mt-4'><Button onClick={sendCommentMessage}>ENVIAR</Button></div>    
                                                </Col>
                                            </Row>
                                        </>
                                        }
                                        {isSuccess && 
                                            <>
                                                <Row>
                                                    <Col>
                                                        <Alert className='alert-success'>Gracias por su Comentario.</Alert>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div className='d-flex justify-content-center mb-4 mt-4'><Button onClick={toggle}>CERRAR</Button></div>    
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                        {isError && 
                                            <>
                                                <Row>
                                                    <Col>
                                                        <Alert className='alert-danger'>Hemos Experimentado un error.</Alert>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div className='d-flex justify-content-center mb-4 mt-4'><Button onClick={toggle}>CERRAR</Button></div>    
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                    </ModalBody>
                                </>
                            </Modal>
                        </div>}
                        <Row className='mb-5'>
                            <Col className='d-flex align-items-center justify-content-center mt-2'>
                                <Button className='back_3 border-0 mt-2 fw-bold ps-4 pe-4' onClick={goBackHandler}>
                                    IR AL INICIO
                                </Button>
                            </Col>
                        </Row>
                </>
            )}
        </div>
    );
};

export default DebitCreditCard;
